<template>
  <b-overlay :show="loading">

    <b-card-actions action-collapse title="Daftar Status Role">
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-button
            v-if="allowCreate()"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-tambah
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            Tambah
          </b-button>
          <b-modal
            v-if="allowCreate()"
            v-model="showModalTambah"
            id="modal-tambah"
            cancel-variant="secondary"
            ok-title="Tambah"
            cancel-title="Batal"
            centered
            title="Form Tambah"
          >
            <b-form>
              <b-form-group>
                <label for="level_id">Level ID</label>
                <b-form-select v-model="form.level_id" :options="levels" label="Level" />
              </b-form-group>
              <b-form-group>
                <label for="menu_id">Menu ID</label>
                <b-form-select v-model="form.menu_id" :options="menu_id" label="Menu" />
              </b-form-group>
              <b-form-group>
                <label for="warna">Warna</label>
                <!-- <b-form-select
                          v-model="form.warna"
                          options="warnaopt"
                          label="Pilih Warna"
                        /> -->
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="primary"
                  class="custom-control-primary"
                >
                  Kuning
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="secondary"
                  class="custom-control-secondary"
                >
                  Abu
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="success"
                  class="custom-control-success"
                >
                  Hijau
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="danger"
                  class="custom-control-danger"
                >
                  Merah
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="warning"
                  class="custom-control-warning"
                >
                  Ungu
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="info"
                  class="custom-control-info"
                >
                  Cyan
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="dark"
                  class="custom-control-info"
                >
                  Hitam
                </b-form-radio>
              </b-form-group>
              <b-form-group>
                <label for="nama_status">Nama Status</label>
                <b-form-input v-model="form.nama_status" />
              </b-form-group>
              <b-form-group>
                <label for="data_status">Data Status</label>
                <b-form-input v-model="form.data_status" />
              </b-form-group>
              <b-form-group>
                <label for="keterangan">Keterangan</label>
                <b-form-textarea
                  v-model="form.keterangan"
                  placeholder="Keterangan"
                  rows="3"
                />
              </b-form-group>
            </b-form>
  
            <template #modal-footer>
              <section class="d-flex justify-content-end align-items-center">
                <b-button size="sm" class="mr-1" variant="success" @click="submit">
                  Tambah
                </b-button>
                <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                  Hapus isian
                </b-button>
                <b-button size="sm" variant="danger" @click="showModalTambah = false">
                  Keluar
                </b-button>
              </section>
            </template>
          </b-modal>
          <b-modal
            v-if="allowUpdate()"
            v-model="showModalEdit"
            id="modal-ubah"
            cancel-variant="secondary"
            ok-title="Ubah"
            cancel-title="Batal"
            centered
            title="Form Ubah"
          >
            <b-form>
              <b-form-group>
                <label for="level_id">Level ID</label>
                <b-form-select v-model="form.level_id" :options="levels" label="Level" />
              </b-form-group>
              <b-form-group>
                <label for="menu_id">Menu ID</label>
                <b-form-select v-model="form.menu_id" :options="menu_id" label="Menu" />
              </b-form-group>
              <b-form-group>
                <label for="warna">Warna</label>
                <!-- <b-form-select
                          v-model="form.warna"
                          options="warnaopt"
                          label="Pilih Warna"
                        /> -->
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="primary"
                  class="custom-control-primary"
                >
                  Kuning
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="secondary"
                  class="custom-control-secondary"
                >
                  Abu
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="success"
                  class="custom-control-success"
                >
                  Hijau
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="danger"
                  class="custom-control-danger"
                >
                  Merah
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="warning"
                  class="custom-control-warning"
                >
                  Ungu
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="info"
                  class="custom-control-info"
                >
                  Cyan
                </b-form-radio>
                <b-form-radio
                  v-model="form.warna"
                  name="some-radio9"
                  value="dark"
                  class="custom-control-info"
                >
                  Hitam
                </b-form-radio>
              </b-form-group>
              <b-form-group>
                <label for="nama_status">Nama Status</label>
                <b-form-input v-model="form.nama_status" />
              </b-form-group>
              <b-form-group>
                <label for="data_status">Data Status</label>
                <b-form-input v-model="form.data_status" />
              </b-form-group>
              <b-form-group>
                <label for="keterangan">Keterangan</label>
                <b-form-textarea
                  v-model="form.keterangan"
                  placeholder="Keterangan"
                  rows="3"
                />
              </b-form-group>
            </b-form>
  
            <template #modal-footer>
              <section class="d-flex justify-content-end align-items-center">
                <b-button size="sm" class="mr-1" variant="info" @click="submit">
                  Ubah
                </b-button>
                <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                  Hapus isian
                </b-button>
                <b-button size="sm" variant="danger" @click="showModalEdit = false">
                  Keluar
                </b-button>
              </section>
            </template>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                v-model="filterBy"
                :options="levels"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- filter level --</option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Search"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
  
        <b-col cols="12">
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(no)="row">
              <strong class="text-center">
                {{ row.index + 1 }}
              </strong>
            </template>
            <template #cell(level)="{item}">
              {{ item.level ? item.level.nama_level : '-' }}
            </template>
            <template #cell(menu)="{item}">
              {{ item.menu ? item.menu.name : '-' }}
            </template>
  
            <template #cell(warna)="data">
              <b-button :variant="warna[1][data.value]">
                {{ warna[0][data.value] }}
              </b-button>
            </template>
  
            <template #cell(actions)="row">
              <b-button
                v-if="allowUpdate()"
                v-b-tooltip.hover.right="'Ubah'"
                size="sm"
                @click="edit(row.item)"
                class="mr-1"
                variant="outline-info"
              >
                <feather-icon icon="EditIcon" />Ubah
              </b-button>
              <!-- </b-dropdown-item> -->
              <!-- <b-dropdown-item> -->
              <b-button
                v-if="allowDelete()"
                v-b-tooltip.hover.right="'Hapus'"
                size="sm"
                @click="remove(row.item)"
                class="mr-1"
                variant="outline-danger"
              >
                <feather-icon icon="TrashIcon" />Hapus
              </b-button>
            </template>
  
            <template #row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">
                    {{ key }}: {{ value }}
                  </li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </b-col>
  
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive"
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  BOverlay,
  BFormRadioGroup,
  BFormRadio,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,
    ToastificationContent,
    BFormTextarea,
    VBTooltip,
    BFormRadioGroup,
    BFormRadio,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BOverlay,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    async filterBy(level_id) {
      if(level_id) {
        this.loading = true
        this.params.level_id = level_id
        await this.getData()
        this.loading = false
      }
    }
  },
  data() {
    return {
      params: {
        order: 'desc'
      },
      filterBy: null,
      showModalEdit: false,
      showModalTambah: false,
      id: null,
      form: {
        menu_id: "",
        level_id: "",
        warna: "",
        nama_status_: "",
        data_status: "",
        keterangan: "",
      },
      menu_id: [],
      levels: [],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "level", label: "Level", sortable: true },
        { key: "menu", label: "Menu", sortable: true },
        { key: "data_status", label: "Data Status" },
        { key: "nama_status", label: "Nama Status", sortable: true },
        { key: "warna", label: "Warna" },
        { key: "keterangan", label: "Keterangan" },
        // { key: "delete", label: "Hapus"},
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      warna: [
        {
          primary: "Kuning",
          success: "Hijau",
          danger: "Merah",
          warning: "Ungu",
          secondary: "Abu-abu",
          dark: "Hitam",
          info: "Cyan",
        },
        {
          primary: "primary",
          success: "success",
          danger: "danger",
          warning: "warning",
          secondary: "secondary",
          dark: "dark",
          info: "info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    this.loading = true
    // Set the initial number of items
    this.getData();
    this.getDatalevel();
    await this.getDatamenu();
    this.loading = false
  },
  methods: {
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModalTambah = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data role ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("statusrole/save", [item])
            .then(() => {
              this.getData();
              this.pesanBerhasilHapus();
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        warna: null,
        nama_status: null,
        data_status: null,
        delete: null,
      };
    },
    submit() {
      if (this.form.nama_status == null || this.form.nama_status == "") {
        this.pesanGagalSimpan();
        return false;
      }
      if (this.id) {
        this.form.id = this.id;
      }
      const payload = this.form;
      this.$store
        .dispatch("statusrole/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.getData();
          this.pesanBerhasilSimpan();
          this.resetForm();
          this.showModalTambah = false;
          this.showModalEdit = false;
        })
        .catch((e) => console.error(e));
      // this.pesanGagalSimpan()
    },
    edit(item) {
      this.id = item.id;
      this.form = item;
      this.showModalEdit = true;
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      this.$store
        .dispatch("statusrole/getData", this.params)
        .then(() => {
          this.items = this.$store.state.statusrole.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    getDatalevel() {
      this.$store.dispatch("level/getData", {}).then(() => {
        let level = JSON.parse(JSON.stringify(this.$store.state.level.datas));
        level.map((item) => {
          item.value = item.id;
          item.text = item.nama_level;
        });
        this.levels = level;
      });
    },
    getlevelName(data) {
      let find = this.levels.find((item) => item.id == data.level_id);
      return find ? find.nama_level : "-";
    },
    getDatamenu() {
      this.$store.dispatch("menu/getData", {}).then(() => {
        let menu = JSON.parse(JSON.stringify(this.$store.state.menu.datas));
        menu.map((item) => {
          item.value = item.id;
          item.text = item.name;
        });
        this.menu_id = menu;
      });
    },
    getmenuName(data) {
      let find = this.menu_id.find((item) => item.id == data.menu_id);
      return find ? find.name : "-";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
